import Vue from 'vue'
import router from '@/router'
import axios from 'axios'
import localStorageService from '@/services/localStorageService'

const apiClient = axios.create({
    baseURL: Vue.prototype.$env.API_URL + '/api/v1',
    headers: {
        'Content-Type': 'application/json'
    }
})

apiClient.interceptors.request.use(
    config => {
        const token = localStorageService.getAuthToken()
        config.headers['Authorization'] = token ? `Bearer ${token}` : null
        return config
    }
)

apiClient.interceptors.response.use(
    response => {
        if (response.data.status) {
            return response.data
        } else {
            return Promise.reject(new Error(response.data.message))
        }
    },
    error => {
        if (!error.response) {
            return Promise.reject(new Error(error.message))
        }
        // Validation error
        else if (error.response.status === 400) {
            // 
        }
        // Auth error
        else if (error.response.status === 401) {
            router.push('/auth/login')
        }
        // Permission error
        else if (error.response.status === 403) {
            router.go(-1)
        }

        return Promise.reject(new Error(error.response.data.message))
    }
)

// Convert object to formdata
apiClient.objectToFormData = (input, formData = new FormData(), parentKey) => {
    if (input && typeof input === 'object' && !(input instanceof Date) && !(input instanceof File)) {
        Object.keys(input).forEach(key => {
            apiClient.objectToFormData(input[key], formData, parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        formData.append(parentKey, input == null ? '' : input)
    }
    return formData
}

export default apiClient